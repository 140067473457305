/* src/styles/SchoolAdminDashboard.css */
.dashboard-content {
  background: #fff;
  padding: 30px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.dashboard-content h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.dashboard-content p {
  font-size: 1.2rem;
  color: #666;
}
