/* src/styles/SchoolAdminSidebar.css */
.schooladmin-sidebar {
    background: #2c3e50;
    color: #ecf0f1;
    width: 250px;
    min-height: 100vh;
    padding: 20px;
    transition: width 0.3s ease;
  }
  
  .schooladmin-sidebar.closed {
    width: 60px;
  }
  
  .schooladmin-sidebar .sidebar-nav {
    display: flex;
    flex-direction: column;
  }
  
  .schooladmin-sidebar .sidebar-item {
    margin-bottom: 15px;
  }
  
  .schooladmin-sidebar .sidebar-link {
    color: #ecf0f1;
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    border: none;
    width: 100%;
    cursor: pointer;
    padding: 8px 10px;
  }
  
  .schooladmin-sidebar .sidebar-link:hover {
    background: #34495e;
    border-radius: 4px;
  }
  
  .schooladmin-sidebar .sub-menu {
    list-style: none;
    padding-left: 15px;
    margin-top: 8px;
  }
  
  .schooladmin-sidebar .sub-menu li {
    margin-bottom: 8px;
  }
  
  .schooladmin-sidebar .sub-menu li a {
    color: #bdc3c7;
    text-decoration: none;
    font-size: 0.9rem;
  }
  
  .schooladmin-sidebar .sub-menu li a:hover {
    color: #ecf0f1;
  }
  