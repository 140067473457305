/* Container and typography */
.assignment-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.assignment-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

/* Filters */
.filters {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.filters input,
.filters select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Table styles */
.students-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.students-table th,
.students-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.students-table th {
  background-color: #f4f4f4;
}

.students-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Action button */
.action-btn {
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-btn:hover {
  background-color: #0056b3;
}

/* Pagination */
.pagination {
  text-align: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 12px;
  border: none;
  background-color: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button.active,
.pagination button:hover {
  background-color: #007bff;
  color: #fff;
}

/* Modal overlay and container */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal h2 {
  text-align: center;
  margin-top: 0;
}

.modal-field {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
}

.modal-field label {
  margin-bottom: 5px;
  font-weight: bold;
}

.modal-field select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Modal buttons */
.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-buttons button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons button:hover {
  opacity: 0.9;
}

.cancel-button {
  background-color: #dc3545;
  color: #fff;
}

.modal-buttons button:not(.cancel-button) {
  background-color: #007bff;
  color: #fff;
}

/* Error message */
.error {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

/* Skeleton view styles */
.skeleton-table {
  width: 100%;
}

.skeleton-row {
  background: #e0e0e0;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skeleton-header {
  height: 50px;
  margin-bottom: 15px;
}
