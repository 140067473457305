/* SchoolAdminLayout.css */

/* Base Layout */
.schooladmin-layout {
  display: flex;
  min-height: 100vh;
  background-color: #f4f7fc;
  overflow-x: hidden;
}

/* Sidebar Styles */
.schooladmin-sidebar {
  width: 250px;
  background-color: #2d3e50;
  color: #fff;
  transition: transform 0.3s ease-in-out;
  position: relative;
}

/* When closed, slide out of view */
.schooladmin-sidebar.closed {
  transform: translateX(-250px);
}

/* Sidebar Header */
.schooladmin-sidebar .sidebar-header {
  padding: 20px;
  font-size: 1.8rem;
  text-align: center;
  background-color: #1a252f;
  border-bottom: 1px solid #3c4b5e;
}

/* Sidebar Navigation */
.schooladmin-sidebar nav {
  padding: 20px 0;
}

.schooladmin-sidebar nav a {
  display: block;
  padding: 10px 20px;
  color: #cfd8dc;
  text-decoration: none;
  border-radius: 4px;
  margin: 0 10px 8px;
  transition: background-color 0.3s ease;
}

.schooladmin-sidebar nav a:hover {
  background-color: #3c4b5e;
}

/* Sidebar Toggle Button (placed inside sidebar) */
.sidebar-toggle-btn {
  position: absolute;
  top: 10px;
  right: -40px;
  background-color: #2d3e50;
  border: none;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  transition: transform 0.3s ease;
}

.sidebar-toggle-btn:hover {
  transform: scale(1.1);
}

/* Main Content Area */
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f4f7fc;
}

/* Header Styles */
.schooladmin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, #3498db, #2980b9);
  color: #fff;
  padding: 15px 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

/* Header Toggle Button (for mobile or collapsing sidebar) */
.schooladmin-header .toggle-button {
  background: transparent;
  border: none;
  font-size: 1.8rem;
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.schooladmin-header .toggle-button:hover {
  transform: scale(1.1);
}

/* Content Area Styles */
.content-area {
  flex: 1;
  padding: 30px 40px;
  animation: fadeInContent 0.5s ease;
  background-color: #fff;
}

/* Content Fade-In Animation */
@keyframes fadeInContent {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles */

/* For tablets and small desktops */
@media (max-width: 992px) {
  .schooladmin-sidebar {
    position: fixed;
    height: 100%;
    z-index: 200;
  }
  
  .main-content {
    margin-left: 250px;
    transition: margin-left 0.3s ease;
  }
  
  /* When sidebar is closed, remove left margin */
  .schooladmin-sidebar.closed + .main-content {
    margin-left: 0;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .schooladmin-layout {
    flex-direction: column;
  }
  
  .schooladmin-sidebar {
    width: 200px;
  }
  
  .schooladmin-sidebar.closed {
    transform: translateX(-200px);
  }
  
  .main-content {
    margin-left: 0;
  }
  
  .schooladmin-header {
    padding: 10px 15px;
  }
  
  .content-area {
    padding: 20px 15px;
  }
  
  .sidebar-toggle-btn {
    right: -35px;
    width: 35px;
    height: 35px;
  }
}

/* For extra-small devices */
@media (max-width: 480px) {
  .schooladmin-sidebar {
    width: 180px;
  }
  
  .schooladmin-sidebar.closed {
    transform: translateX(-180px);
  }
  
  .schooladmin-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
  
  .schooladmin-header .toggle-button {
    align-self: flex-end;
  }
  
  .content-area {
    padding: 15px;
  }
}
