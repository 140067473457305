/* Base styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #f4f7f9;
  color: #333;
}

/* Container for the form */
.form-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.6s ease;
}

/* Heading styling */
.form-container h2 {
  text-align: center;
  margin-bottom: 25px;
  font-size: 2rem;
  color: #2c3e50;
  position: relative;
}
.form-container h2::after {
  content: '';
  display: block;
  width: 80px;
  height: 3px;
  background-color: #3498db;
  margin: 10px auto 0;
  border-radius: 2px;
}

/* Fieldset and legend styling */
fieldset {
  border: 1px solid #ddd;
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  animation: fadeIn 0.5s ease;
}
legend {
  padding: 0 10px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #3498db;
}

/* Label and input styles */
label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #34495e;
}
input[type="text"],
input[type="date"],
textarea,
select {
  width: 100%;
  padding: 12px 14px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 0.95rem;
}
input[type="text"]:focus,
input[type="date"]:focus,
textarea:focus,
select:focus {
  border-color: #3498db;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
  outline: none;
}

/* Button styles */
button {
  background-color: #3498db;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 10px;
}
button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Specific buttons */
.delete-button {
  background-color: #e74c3c;
}
.delete-button:hover {
  background-color: #c0392b;
}
button[type="button"] {
  background-color: #2ecc71;
}
button[type="button"]:hover {
  background-color: #27ae60;
}

/* Family member styling */
.family-member {
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #eee;
  border-radius: 6px;
  background: #f9f9f9;
  transition: transform 0.3s ease;
}
.family-member:hover {
  transform: scale(1.02);
}

/* Error message styling */
.error {
  background-color: #ffe6e6;
  color: #e74c3c;
  padding: 10px 15px;
  border: 1px solid #e74c3c;
  border-radius: 6px;
  margin-bottom: 20px;
  text-align: center;
}

/* Animations */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-container {
    padding: 20px 30px;
    margin: 20px;
  }
  .form-container h2 {
    font-size: 1.8rem;
  }
  input[type="text"],
  input[type="date"],
  textarea,
  select {
    padding: 10px 12px;
  }
}

@media (max-width: 480px) {
  .form-container {
    padding: 15px 20px;
    margin: 10px;
  }
  .form-container h2 {
    font-size: 1.6rem;
  }
  button {
    padding: 10px 15px;
    font-size: 0.9rem;
  }
  .family-member {
    padding: 10px;
  }
}
