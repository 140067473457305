/* CreateSchool.css */

.create-school-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f4f6f8;
    padding: 20px;
  }
  
  .create-school-card {
    background: #fff;
    padding: 40px;
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .create-school-card h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: 600;
    color: #555;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 80px;
  }
  
  .error-message {
    color: #e74c3c;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .success-message {
    color: #27ae60;
    text-align: center;
    margin-bottom: 10px;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 12px;
    background: #1abc9c;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  button[type="submit"]:hover:not(:disabled) {
    background: #16a085;
  }
  
  button[type="submit"]:disabled {
    background: #a5d6c0;
    cursor: not-allowed;
  }
  