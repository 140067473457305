/* ManageSchools.css */

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global Body Styles */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: #f4f6f8;
  color: #333;
}

/* Container for the Entire Page */
.manage-schools-container {
  display: flex;
  min-height: 100vh;
}

/* Sidebar Styles */
.sidebar {
  width: 250px;
  background: #2c3e50;
  color: #ecf0f1;
  transition: all 0.3s ease;
  padding: 20px;
}

.sidebar.active {
  width: 200px;
}

.sidebar-header h3 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 1px;
}

/* Navigation Links in Sidebar */
.sidebar nav {
  display: flex;
  flex-direction: column;
}

.sidebar .nav-link {
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  text-decoration: none;
  color: #bdc3c7;
  transition: background 0.3s ease;
}

.sidebar .nav-link:hover {
  background: #34495e;
  color: #ecf0f1;
}

.sidebar .nav-link.active {
  background: #1abc9c;
  color: #fff;
}

/* Main Content Area */
.main-content {
  flex: 1;
  background: #fff;
  padding: 20px;
  overflow-y: auto;
}

/* Header Section */
.management-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.menu-toggle {
  background: none;
  border: none;
  cursor: pointer;
}

.header-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
}

.logout-button {
  background: #e74c3c;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.logout-button:hover {
  background: #c0392b;
}

/* Page Content Section */
.page-content {
  margin-top: 20px;
}

/* Controls Container */
.controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

/* Entries Selector */
.entries-selector {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.entries-selector span {
  margin-right: 5px;
}

.entries-dropdown {
  padding: 5px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f4f6f8;
  color: #333;
  margin: 0 5px;
  cursor: pointer;
}

/* Search Input */
.search-container {
  position: relative;
}

.search-input {
  padding: 8px 12px;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #1abc9c;
}

/* Add New School Button */
.add-school-container {
  margin-bottom: 20px;
  text-align: right;
}

.add-school-button {
  display: inline-block;
  background: #1abc9c;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background 0.3s ease;
}

.add-school-button:hover {
  background: #16a085;
}

/* Table Container and Table Styles */
.table-container {
  overflow-x: auto;
}

.school-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.school-table thead {
  background: #1abc9c;
  color: #fff;
}

.school-table th,
.school-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.school-table tbody tr:hover {
  background: #f9f9f9;
}

/* Action Buttons Styles */
.action-buttons {
  display: flex;
  gap: 8px;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background 0.3s ease;
}

.action-button:hover {
  background: #f0f0f0;
}

.view-button {
  color: #3498db;
}

.edit-button {
  color: #f39c12;
}

.delete-button {
  color: #e74c3c;
}

/* Pagination Styles */
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.9rem;
}

.entries-info {
  margin-bottom: 10px;
}

.pagination-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.page-button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  background: #f4f6f8;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.page-button:hover:not(:disabled) {
  background: #1abc9c;
  color: #fff;
}

.page-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.current-page {
  font-weight: 600;
}
