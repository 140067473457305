/* styles/SuperAdminHeader.css */
.superadmin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1abc9c;
    padding: 10px 20px;
    color: #fff;
  }
  
  .header-left {
    display: flex;
    align-items: center;
  }
  
  .menu-toggle {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-right: 15px;
  }
  
  .header-title {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .header-right {
    display: flex;
    align-items: center;
  }
  
  .profile-button,
  .logout-button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-left: 15px;
  }
  