/* General page styling */
body {
    font-family: 'Poppins', sans-serif;
    background-color: #f9f9f9;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .assign-section-container {
    max-width: 800px;
    margin: 50px auto;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px 30px;
    animation: fadeIn 0.6s ease;
  }
  
  h2 {
    font-size: 28px;
    color: #4a4a4a;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
  }
  
  h2::after {
    content: '';
    display: block;
    width: 80px;
    height: 3px;
    background-color: #4caf50;
    margin: 10px auto 0 auto;
    border-radius: 2px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    display: block;
    color: #4caf50;
  }
  
  select,
  input[type="text"] {
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  select:focus,
  input[type="text"]:focus {
    border-color: #4caf50;
    box-shadow: 0 0 4px rgba(76, 175, 80, 0.4);
    outline: none;
  }
  
  /* Button styling */
  button {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: #4caf50;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .delete-button {
    background-color: #e74c3c;
    color: #fff;
  }
  
  .delete-button:hover {
    background-color: #c0392b;
  }
  
  /* Sections list */
  .sections-container {
    margin-top: 20px;
  }
  
  .sections-container h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .sections-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .section-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background: #f3f3f3;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: box-shadow 0.3s ease, transform 0.2s ease;
  }
  
  .section-item:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
  
  /* New section form */
  .new-section-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    background: #f9f9f9;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    animation: slideIn 0.4s ease;
  }
  
  .new-section-form input {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .new-section-form button {
    align-self: flex-end;
    width: 100px;
  }
  
  /* Error styling */
  .error {
    color: #e74c3c;
    background: #ffe6e6;
    padding: 10px;
    border: 1px solid #e74c3c;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .assign-section-container {
      padding: 15px;
    }
  
    h2 {
      font-size: 24px;
    }
  
    button {
      font-size: 12px;
      padding: 8px 15px;
    }
  
    .section-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  
    .new-section-form {
      gap: 5px;
    }
  }
  