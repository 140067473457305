.settings-container {
    padding: 20px;
  }
  
  .settings-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .settings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    justify-content: center;
  }
  
  .settings-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 15px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .settings-btn:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 600px) {
    .settings-grid {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }
  