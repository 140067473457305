/* styles/SuperAdminSidebar.css */
.superadmin-sidebar {
    background: #2c3e50;
    color: #ecf0f1;
    width: 220px;
    min-height: 100vh;
    transition: width 0.3s ease;
    padding: 20px;
  }
  
  .superadmin-sidebar.closed {
    width: 60px;
  }
  
  .sidebar-header h2 {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .sidebar-nav {
    display: flex;
    flex-direction: column;
  }
  
  .nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #bdc3c7;
    padding: 10px 5px;
    transition: background 0.3s ease, color 0.3s ease;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .nav-link:hover {
    background: #34495e;
    color: #ecf0f1;
  }
  
  .nav-link span {
    margin-left: 10px;
  }
  