/* StudentsList.css */

/* Base Container */
.students-container {
  padding: 2rem;
  background-color: #f4f7fc;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in;
}

/* Header Section */
.students-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  text-align: center;
}

.students-header h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
  animation: slideInDown 0.5s ease;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.controls input {
  padding: 0.75rem;
  width: 250px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.controls input:focus {
  border-color: #3498db;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.2);
  outline: none;
}

.create-btn {
  padding: 0.75rem 1.25rem;
  background-color: #28a745;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.create-btn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

/* Table Styling */
.students-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.students-table thead {
  background: linear-gradient(90deg, #3498db, #2980b9);
  color: #171616;
}

.students-table th,
.students-table td {
  padding: 1rem;
  border: 1px solid #eee;
  text-align: left;
}

.students-table tbody tr {
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.students-table tbody tr:hover {
  background-color: #f1faff;
}

/* Action Buttons */
.action-btn {
  padding: 0.5rem 0.75rem;
  margin-right: 0.25rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.action-btn.view {
  background-color: #007bff;
  color: #fff;
}

.action-btn.edit {
  background-color: #ffc107;
  color: #000;
}

.action-btn.roll {
  background-color: #6f42c1;
  color: #fff;
}

.action-btn.delete {
  background-color: #dc3545;
  color: #fff;
}

.action-btn:hover {
  transform: scale(1.05);
}

/* Pagination Styling */
.pagination {
  margin-top: 1.5rem;
  text-align: center;
}

.pagination button {
  padding: 0.75rem 1.25rem;
  margin: 0.25rem;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination button.active,
.pagination button:hover {
  background-color: #3498db;
  color: #fff;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  animation: fadeIn 0.3s ease;
}

.modal {
  background: #fff;
  border-radius: 8px;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  animation: slideInUp 0.4s ease;
  position: relative;
}

.modal h2 {
  margin-top: 0;
  color: #2c3e50;
}

.modal p {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.modal .error {
  margin: 1rem 0;
  color: #dc3545;
  font-weight: bold;
}

.modal input[type="text"] {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 1rem 0;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.modal-buttons button {
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-buttons button:first-of-type {
  background-color: #3498db;
  color: #fff;
}

.modal-buttons button:first-of-type:hover {
  background-color: #2980b9;
}

.modal-buttons .cancel-button {
  background-color: #e74c3c;
  color: #fff;
}

.modal-buttons .cancel-button:hover {
  background-color: #c0392b;
}

/* Skeleton Loading */
.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 0.5rem 0;
  position: relative;
  overflow: hidden;
}
.skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.6), transparent);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% { left: -100%; }
  50% { left: 100%; }
  100% { left: 100%; }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInDown {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideInUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Responsive Design */
@media (max-width: 768px) {
  .students-header {
    flex-direction: column;
  }
  
  .controls {
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .controls input {
    width: 100%;
  }
  
  .students-table th,
  .students-table td {
    padding: 0.75rem 0.5rem;
    font-size: 0.9rem;
  }
  
  .action-btn {
    font-size: 0.9rem;
    padding: 0.4rem 0.6rem;
  }
}
