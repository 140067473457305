/* styles/SuperAdminLayout.css */
.app-layout {
    display: flex;
  }
  
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content-area {
    padding: 20px;
    background: #f4f6f8;
    flex: 1;
  }
  