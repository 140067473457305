/* Frontend: src/styles/ClassesPage.css */
.classes-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.session-selector {
  text-align: center;
  padding: 3rem;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.session-selector h2 {
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

.session-selector select {
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  border: 2px solid #3498db;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.session-selector select:hover {
  border-color: #2980b9;
}

.classes-management {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.add-button {
  background: #3498db;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.add-button:hover {
  background: #2980b9;
}

.class-form {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.class-form input {
  display: block;
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.form-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.form-actions button {
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.form-actions button[type="submit"] {
  background: #27ae60;
  color: white;
  border: none;
}

.form-actions button[type="submit"]:hover {
  background: #219a52;
}

.form-actions button[type="button"] {
  background: #e74c3c;
  color: white;
  border: none;
}

.form-actions button[type="button"]:hover {
  background: #c0392b;
}

.classes-list {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.class-card {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.class-card:hover {
  transform: translateY(-3px);
}

.error-banner {
  background: #e74c3c;
  color: white;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.empty-state {
  text-align: center;
  color: #7f8c8d;
  padding: 2rem;
  border: 2px dashed #bdc3c7;
  border-radius: 8px;
}