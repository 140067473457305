/* SuperAdminDashboard.css */

/* Container for the entire dashboard content */
.dashboard-content {
  background: #fff;
  padding: 30px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Main Heading */
.dashboard-content h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

/* Statistics Container */
.admin-stats {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

/* Individual Stat Cards */
.stat-item {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  width: 200px;
  text-align: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-5px);
}

/* Value displayed in the stat card */
.stat-value {
  display: block;
  font-size: 2rem;
  font-weight: bold;
  color: #1abc9c;
  margin-bottom: 10px;
}

/* Label for the stat card */
.stat-label {
  display: block;
  font-size: 1rem;
  color: #777;
}
