/* src/styles/ClassSubjectPage.css */
.class-subjects-page {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .selection-filters {
    display: flex;
    gap: 1rem;
    margin: 2rem 0;
  }
  
  .selection-filters select {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    min-width: 200px;
  }
  
  .subjects-table-container {
    overflow-x: auto;
    background: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
  }
  
  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  th {
    background-color: #f8f9fa;
  }
  
  input[type="checkbox"] {
    transform: scale(1.2);
  }
  
  .save-button {
    margin-top: 1rem;
    padding: 0.8rem 2rem;
    background: #2196F3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
  }
  
  .save-button:disabled {
    background: #90caf9;
    cursor: not-allowed;
  }
  
  .error {
    color: #dc3545;
    padding: 1rem;
    background: #f8d7da;
    border-radius: 4px;
    margin: 1rem 0;
  }
  
  .success {
    color: #28a745;
    padding: 1rem;
    background: #d4edda;
    border-radius: 4px;
    margin: 1rem 0;
  }