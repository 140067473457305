/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
  color: #333;
}

.page-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  color: #4A90E2;
  animation: fadeIn 1s ease-in-out;
}

/* Form Styles */
.form-container {
  background: linear-gradient(135deg, #ffffff, #f7f8fa);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
  animation: slideIn 1s ease-in-out;
}

.form-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #4A90E2;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: all 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #4A90E2;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

.add-scale-btn {
  display: inline-block;
  background-color: #34C759;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.add-scale-btn:hover {
  transform: scale(1.05);
}

.message {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #34C759;
}

/* Button Styles */
button {
  cursor: pointer;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
}

button:hover {
  transform: translateY(-3px);
}

.delete-btn {
  background-color: #FF3B30;
  color: white;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
}

.delete-btn:hover {
  background-color: #d32f2f;
}

/* List Styles */
.list-container {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease-in-out;
}

.list-container h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

li:hover {
  background-color: #f7f8fa;
}

/* Session Selector */
.session-selector {
  margin-bottom: 30px;
  text-align: center;
}

.session-selector select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.session-selector select:focus {
  border-color: #4A90E2;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
