/* src/styles/SchoolAdminHeader.css */
.schooladmin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1abc9c;
    padding: 10px 20px;
    color: #fff;
  }
  
  .schooladmin-header .header-left {
    display: flex;
    align-items: center;
  }
  
  .schooladmin-header .menu-toggle {
    background: none;
    border: none;
    margin-right: 15px;
    color: #fff;
    cursor: pointer;
  }
  
  .schooladmin-header h1 {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .schooladmin-header .header-right {
    display: flex;
    align-items: center;
  }
  
  .schooladmin-header .header-btn {
    background: none;
    border: none;
    margin-left: 15px;
    color: #fff;
    cursor: pointer;
  }
  