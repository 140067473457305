/* General Page Styling */
.page-container {
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.page-title {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Session and Class Selector Styling */
.session-selector, .class-selector {
  margin-bottom: 20px;
  animation: slideInLeft 0.5s ease-in-out;
}

.session-selector label, .class-selector label {
  font-size: 1.2rem;
  color: #555;
  margin-right: 10px;
}

.session-selector select, .class-selector select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.session-selector select:hover, .class-selector select:hover {
  border-color: #007bff;
}

/* Student List Styling */
.student-list {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideInRight 0.5s ease-in-out;
}

.student-list h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
}

.student-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s ease;
}

.student-item:hover {
  background-color: #f1f1f1;
}

.student-info {
  display: flex;
  flex-direction: column;
}

.student-name {
  font-size: 1.2rem;
  color: #333;
}

.student-roll {
  font-size: 1rem;
  color: #777;
}

.give-marks-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.give-marks-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Result Entry Styling */
.result-info {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  animation: fadeIn 1s ease-in-out;
}

.result-info p {
  font-size: 1.2rem;
  color: #333;
}

.exam-term-selector {
  margin-bottom: 20px;
  animation: slideInLeft 0.5s ease-in-out;
}

.exam-term-selector label {
  font-size: 1.2rem;
  color: #555;
  margin-right: 10px;
}

.exam-term-selector select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.exam-term-selector select:hover {
  border-color: #007bff;
}

/* Subjects Table Styling */
.subjects-entry {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideInRight 0.5s ease-in-out;
}

.subjects-entry h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
}

.subjects-table {
  width: 100%;
  border-collapse: collapse;
}

.subjects-table th, .subjects-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.subjects-table th {
  background-color: #007bff;
  color: #fff;
}

.subjects-table tr:hover {
  background-color: #f1f1f1;
}

.subjects-table input[type="number"] {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.subjects-table input[type="number"]:focus {
  border-color: #007bff;
  outline: none;
}

.subjects-table input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Result Actions Styling */
.result-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  animation: fadeIn 1s ease-in-out;
}

.submit-btn, .back-btn {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-btn {
  background-color: #28a745;
  color: #fff;
}

.submit-btn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.back-btn {
  background-color: #dc3545;
  color: #fff;
}

.back-btn:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

/* Animations */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Skeleton Loader Styling */
.skeleton-loader {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 37%, #f0f0f0 63%);
  background-size: 400% 100%;
  border-radius: 4px;
  animation: shimmer 1.2s ease-in-out infinite;
}

@keyframes shimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}
