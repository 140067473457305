/* src/styles/AssignSubjectSchool.css */
.assign-subject-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .assign-subject-heading {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .subject-list {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .subject-list h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .subject-item {
    font-size: 16px;
    color: #555;
    padding: 8px;
    border-bottom: 1px solid #eee;
  }
  
  .subject-item:last-child {
    border-bottom: none;
  }
  
  .add-subject-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .add-subject-form h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
  }
  
  .input-field:focus {
    border-color: #4a90e2;
    outline: none;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #3578b4;
  }
  
  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .assign-subject-container {
      padding: 15px;
    }
  
    .subject-list {
      margin-bottom: 20px;
    }
  
    .add-subject-form {
      margin-bottom: 20px;
    }
  }
  