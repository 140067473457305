/* src/components/StudentDetailView.css */
.student-detail-view {
    padding: 2rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 2rem auto;
    max-width: 800px;
  }
  
  .student-detail-view h2 {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .detail-item {
    margin-bottom: 0.5rem;
  }
  
  .personal-info,
  .enrollment-info,
  .family-info,
  .bank-info {
    margin-bottom: 1.5rem;
  }
  
  .personal-info h3,
  .enrollment-info h3,
  .family-info h3,
  .bank-info h3 {
    margin-bottom: 0.75rem;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.25rem;
  }
  